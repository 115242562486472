import { Title } from '@solidjs/meta';
import {
	Button,
	Heading,
	Link,
	Container,
	BreadcrumbItem,
	Page,
	HorizontalRule,
	Section,
	Picture,
	TextLink,
} from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { useLocation } from '@solidjs/router';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { PictureSection } from '../../../components/layouts/picture-section';
import type { RouteDefinition } from '@solidjs/router';

export default function CareersPage() {
	const loc = useLocation();

	return (
		<NoHydration>
			<Title>Career Opportunities | Troon</Title>
			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/web/about/headquarters.jpeg`}
				crop="center"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Career Opportunities</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					Career Opportunities
				</Heading>
			</Hero>

			<Page>
				<Container>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/web/photo/career-opportunities/culture.jpg`}>
						<Heading as="h2">A culture of hospitality</Heading>
						<p>
							Back in 1990, our founder, Dana Garmany, created a revolutionary concept. It was a business model focused
							on managing upscale public golf courses based on hospitality-caliber customer service principles and
							superior agronomic conditions. It was then the “Member for a Day” experience was born. Behind the vision
							of this extraordinary leader and many others who since have followed, Troon has grown into the worldwide
							leader in golf and golf-related hospitality. It has become a model that has not only created unforgettable
							experiences people all over the globe, but has resulted in remarkable career opportunities and growth for
							many who have seized an opportunity and reach milestones they might thought were never possible.
						</p>
						<div>
							<Button
								class="size-fit"
								as={Link}
								href="https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc"
							>
								View job openings
							</Button>
						</div>
					</PictureSection>
				</Container>

				<Container>
					<HorizontalRule />
				</Container>

				<Container>
					<Section>
						<Heading as="h2" size="h3">
							Discover your path
						</Heading>
						<p>Select a category of interest below to view job openings</p>
						<ul class="grid grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-8">
							<For each={areas}>
								{(area) => (
									<li class="group relative flex shrink grow basis-full flex-col gap-4">
										<div class="overflow-hidden rounded">
											<Picture
												src={area.image}
												width={300}
												height={400}
												sizes="(min-width: 1024px) 25vw, 50vw"
												alt=""
												// eslint-disable-next-line tailwindcss/no-arbitrary-value
												class="pointer-events-none aspect-[3/4] w-full object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
												loading="lazy"
												crop="face"
											/>
										</div>
										<div class="flex flex-col gap-1">
											<TextLink
												href={area.href}
												class="text-lg font-semibold text-neutral-900 after:absolute after:inset-0 after:z-0"
											>
												{area.title}
											</TextLink>
										</div>
									</li>
								)}
							</For>
						</ul>
					</Section>
				</Container>

				<Section appearance="primary">
					<Container size="small">
						<blockquote
							// eslint-disable-next-line tailwindcss/no-custom-classname
							class="quotes flex flex-col items-center gap-8 text-center text-white"
						>
							<q class="text-xl font-semibold md:text-3xl">
								It’s been a dream come true for me to have this kind of job which was never a job, it was a career.
							</q>

							<p class="flex flex-col items-center gap-2">
								<Picture
									src="https://images.ctfassets.net/rdsy7xf5c8dt/2bWgca9kBxu9eKVbD9DeCZ/c340d56f1dfb7998321cc3c639d2346f/dana-garmany.jpg"
									width={100}
									height={100}
									sizes="2rem"
									alt="Headshot of Troon Founder Dana Garmany"
									loading="lazy"
									class="size-16 rounded-full"
									crop="face"
								/>
								<span class="text-lg font-semibold">Dana Garmany</span>
								<span>Chairman and Founder, Troon</span>
							</p>
						</blockquote>
					</Container>
				</Section>

				<Container>
					<PictureSection
						src={`${getConfigValue('IMAGE_HOST')}/web/photo/career-opportunities/benefits.png`}
						loading="lazy"
					>
						<Heading as="h2">Benefits</Heading>
						<p>
							At Troon, we believe our Associates are our greatest strength and competitive advantage. It is Troon's
							goal to provide a comprehensive benefits package that helps associates manage their healthcare and
							retirement needs in order to enhance their quality of life and provide peace of mind.
						</p>
						<div>
							<Button
								appearance="secondary"
								class="size-fit"
								as={Link}
								href="/about/career-opportunities/employee-benefits"
							>
								Explore benefits
							</Button>
						</div>
					</PictureSection>
				</Container>

				<Container>
					<HorizontalRule />
				</Container>

				<Section>
					<Container class="relative z-10 items-center py-24 text-center">
						<Heading as="h2" size="h1">
							Join our team!
						</Heading>
						<Button
							class="size-fit"
							as={Link}
							href="https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc"
						>
							View all job openings
						</Button>
					</Container>
				</Section>
			</Page>
		</NoHydration>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const areas: Array<{ title: string; href: string; image: string }> = [
	{
		title: 'Food & Beverage',
		href: 'https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc&f5=aUu67bKxm0KDms2moPhMnw',
		image: `${getConfigValue('IMAGE_HOST')}/web/about/career-opportunities.jpeg`,
	},
	{
		title: 'Golf',
		href: 'https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc&f5=C-0Pj9SZSE2lyxeqlZgonA',
		image: `${getConfigValue('IMAGE_HOST')}/web/photo/career-opportunities/golf.jpg`,
	},
	{
		title: 'Racquet Sports',
		href: 'https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc&f5=27CJOe5oHU6y6Txp-G5ycg',
		image: `${getConfigValue('IMAGE_HOST')}/web/photo/career-opportunities/tennis.jpg`,
	},
	{
		title: 'Corporate',
		href: 'https://recruiting2.ultipro.com/TRO1001TROO/JobBoard/2b13054b-60bb-410d-9136-e52c1c7d9720/?q=&o=postedDateDesc&f4=ilY92uRKilmbVyCJcSww4w',
		image: `${getConfigValue('IMAGE_HOST')}/web/about/headquarters.jpeg`,
	},
];
